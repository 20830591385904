<template>
  <main class="container full py-5 border-top">
    <div class="notFound text-center">
      <h2>404</h2>
      <p>Sistemde böyle bir kullanıcı bulunamadı!</p>
    </div>
  </main>
</template>

<script>
export default {
  name: "Auth",
  created() {
    setTimeout(() => {
      this.logout();
    }, 3000);
  },
  methods: {
    delete_cookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    async logout() {
      localStorage.clear();
      this.delete_cookie("token");
      //this.$msal.signOut();
      // await this.$msalInstance
      //   .logoutRedirect({})
      //   .then(() => {})
      //   .catch(() => {});
    },
  },
};
</script>

<style></style>
